:root {
  /* General */
  --black: #3C4856;
  --grey: #556477;
  --light-grey: #f1f2f3;
  --blue: #53B3CB;
  --yellow:  #F9C22E;
  --white: #ffffff;
  --light-blue: #E7EAEE;
  --red: #D95D39;

  --canvas-colour: var(--light-grey);
  --bg-colour: var(--white);
  --text-colour: var(--black);
  --link-colour: var(--blue);
  --graphics-main-colour: var(--blue);
  --graphics-secondary-colour: var(--yellow);
  --graphics-third-colour: var(--light-blue);
  --accent-colour: var(--red);

  /* Semantical */
  --phonetical-colour: var(--grey);
  --lastname-colour: var(--grey);
  --subtitle-colour: var(--accent-colour);
  --milestone-year-colour: var(--black);
  --company-location-colour: var(--text-colour);
}

body {
  background-color: var(--canvas-colour);
}

.App {
  font-family: "Prompt", sans-serif;
  font-weight: 500px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: var(--link-colour);
  text-decoration: none;
  font-weight: 500;
}

em {
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 800px;
  color: var(--text-colour);
  background-color: white;
  padding: 40px 50px 40px 40px;
}

.personal-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  flex-flow: wrap;
}

.personal-details-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
}

.container-main-content {
  display: flex;
  flex-direction: column;
}

.phonetical {
  color: var(--phonetical-colour);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 2px;
  opacity: .8;
}

.title {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 25px;
  margin-top: 10px;
}

.last-name {
  font-size: 2.2rem;
  font-weight: 500;
  color: var(--lastname-colour);
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--subtitle-colour);
  margin-top: 10px;
  opacity: .8;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 13px;
}

.contact-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
}

a.contact-details-item-text {
  padding-top: 4px;
}

.icon {
  padding-right: 10px;
  width: 18px;
}

.bio .first-paragraph {
  margin-top: 0;
}

/* EXPERIENCE */

.timeline-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.milestone {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.milestone-label {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  min-width: 53px;
  margin-right: 12px;
  flex-shrink: 1;
}

.milestone-month {
  font-weight: 800;
  font-size: 1rem;
  color: var(--milestone-year-colour);
  opacity: .9;
}

.milestone-year {
  margin-right: 15px;
  color: var(--text-colour);
}

.milestone-duration {
  font-weight: 600;
  color: var(--accent-colour);
  margin-bottom: 20px;
}

.circle {
  width: 18px;
  height: 18px;
  border: 10px solid;
  border-radius: 50%;
  flex-shrink: 0;
}

.circle.filled {
  background-color: var(--graphics-main-colour);
}

.circle.outlined {
  width: 11px;
  height: 11px;
  background-color: var(--graphics-secondary-colour);
}

.circle.today {
  background-color: var(--accent-colour);
}

.circle.travel {
  background-color: var(--white);
  z-index: 1000;
}

.line {
  border-left: 8px solid var(--graphics-third-colour);
  margin-left: 80px;
  padding: 40px 0 10px 32px;
}

.line.dashed {
  border-left-style: dashed;
}

.milestone-title {
  font-size: 1.2rem;
  font-weight: 900;
  margin-right: 10px;
}

.milestone-text {
  margin-left: 15px;
  display: flex;
  align-items: first baseline;
  flex-wrap: wrap;
}

.company-name {
  font-weight: 500;
}

.company-location {
  margin-top: 10px;
  padding-bottom: 10px;
  color: var(--company-location-colour);
}

@media screen and (min-width: 600px) {
  .App {
    padding: 5% 10%;
  }
  body {
    background-color: var(--bg-colour);
 }
 .container {
    border-radius: 12px;
    box-shadow: 5px 5px 15px #e1e2e3;
 }
}